import { LanguageEnum } from '../constants';
import {
  DanishLanguage,
  DutchLanguage,
  EnglishLanguage,
  GermanLanguage,
  NorwegianLanguage,
  PortugueseLanguage,
  SpanishLanguage,
  SwedishLanguage,
} from '../constants/language.constants';

function getLanguageEnum(language: string): LanguageEnum {
  let languageId = null;

  switch (language) {
    case NorwegianLanguage.code: {
      languageId = LanguageEnum.Norwegian;
      break;
    }
    case EnglishLanguage.code: {
      languageId = LanguageEnum.English;
      break;
    }
    case SwedishLanguage.code: {
      languageId = LanguageEnum.Swedish;
      break;
    }
    case DanishLanguage.code: {
      languageId = LanguageEnum.Danish;
      break;
    }
    case SpanishLanguage.code: {
      languageId = LanguageEnum.Spanish;
      break;
    }
    case DutchLanguage.code: {
      languageId = LanguageEnum.Dutch;
      break;
    }
    case GermanLanguage.code: {
      languageId = LanguageEnum.German;
      break;
    }
    case PortugueseLanguage.code: {
      languageId = LanguageEnum.Portuguese;
      break;
    }
    default: {
      languageId = LanguageEnum.English;
    }
  }

  return languageId;
}

function getLanguageCode(languageId: LanguageEnum): string {
  let language = '';
  switch (languageId) {
    case LanguageEnum.Norwegian: {
      language = NorwegianLanguage.code;
      break;
    }
    case LanguageEnum.English: {
      language = EnglishLanguage.code;
      break;
    }
    case LanguageEnum.Swedish: {
      language = SwedishLanguage.code;
      break;
    }
    case LanguageEnum.Danish: {
      language = DanishLanguage.code;
      break;
    }
    case LanguageEnum.Spanish: {
      language = SpanishLanguage.code;
      break;
    }
    case LanguageEnum.Dutch: {
      language = DutchLanguage.code;
      break;
    }
    case LanguageEnum.German: {
      language = GermanLanguage.code;
      break;
    }
    case LanguageEnum.Portuguese: {
      language = PortugueseLanguage.code;
      break;
    }
    default: {
      language = EnglishLanguage.code;
      break;
    }
  }
  return language;
}

export const LanguageHelper = {
  getLanguageEnum,
  getLanguageCode,
};
